import {useEffect, useState} from 'react';
// import logo from './profile_old.png';
import './App.css';
const axios = require('axios');

function Movie(props){
  function download(e){
    alert("Downloading " + props.movie.title_long);
    axios.post("https://eojs6b7u2dgzm5q.m.pipedream.net", props.movie)
  }

  return (
    <div className="Movie" key={props.movie.id}>
      <p>{props.
        movie.title_long}</p>
      <button onClick={download}>
        download
      </button>
    </div>
  );
}

function MovieList(props){


  let output = [];
  for (let movie of props.movies){
    output.push(<Movie movie={movie}/>)
    console.log(movie);
    // output.push(
    //   <div className="Movie" key={movie.id}>
    //     <p>{movie.title_long}</p>
    //     <button onClick={downloadMovie}>
    //       download
    //     </button>
    //   </div>
    // );
  }
  return output;
}

function SearchBar() {
  const [movies, setMovies] = useState([]);

  function searchMovies(e){
    e.preventDefault();
    const query = e.target.children[0].value;
    console.log("Searching API for: " + query);
    axios.get("https://yts.mx/api/v2/list_movies.json?sort_by=year&query_term=" + query)
      .then(res => {
        // console.log(res.data.data)
        const data = res.data.data;
        console.log("Found " + data.movie_count + " results")
        setMovies(data.movies);
      })
  }

  return (
    <>
      <form onSubmit={searchMovies} style={{margin: 15}}>
        <input
          type="search"
          placeholder="Search movies..."
        />
      </form>
      <MovieList movies={movies}/>
    </>
  )
}

function App() {
  // <img src={logo} className="App-logo" alt="logo" />
  return (
    <div className="App">
      <header className="App-header">
        <p style={{margin: 0}}>moviemouse</p>
        <p style={{fontSize: "15px", margin: 0}}>v0.1</p>
        <SearchBar/>
      </header>
    </div>
  );
}

export default App;
